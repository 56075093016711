import Account from "@/plugins/api/factories/Account";
import Auth from "@/plugins/api/factories/Auth";
import Settings from "@/plugins/api/factories/Settings";
import appBridge from "@/mixins/app-bridge";
// import axios from "axios";
// import api from "@/plugins/api/EntryPoint";

export const state = {
  hidden: false,
  guest: false,
  authLog: "",
  nativeData: null,
  authToken: null,
  appTheme: "dark",
  themeReady: false,
  accounts: {},
  account: null,
  eic: null,
  accountNumber: null,
  phoneNumber: null,
  locale: process.env.VUE_APP_I18N_DEFAULT_LOCALE,
  requestedPage: null,
};

export const mutations = {
  WRITE_AUTH_LOG(state, logString) {
    state.authLog = state.authLog + ";" + logString;
  },
  SET_APP_THEME(state, appTheme) {
    document.body.classList.remove("app-theme-" + state.appTheme);
    state.appTheme = appTheme;
    state.themeReady = true;
    document.body.classList.add("app-theme-" + appTheme);

    Settings.setTheme(appTheme);
  },
  CHANGE_USER_THEME(state, theme) {
    Settings.setTheme(theme);
  },
  SET_REQUESTED_PAGE(state, requestedPage) {
    state.requestedPage = requestedPage;
  },
  SET_ACCOUNT_NUMBER(state, accountNumber) {
    state.accountNumber = accountNumber;
  },
  SET_NATIVE_DATA(state, nativeData) {
    state.nativeData = nativeData;
  },
  SET_AUTH_TOKEN(state, token) {
    state.authToken = token;
  },
  SET_ACCOUNTS(state, accounts) {
    state.accounts = accounts;
  },
  SET_PHONE_NUMBER(state, phoneNumber) {
    state.phoneNumber = phoneNumber;
  },
  SET_LOCALE(state, locale) {
    let locales = process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(",");
    if (locales.indexOf(locale) >= 0) {
      state.locale = locale;
    } else {
      state.locale = process.env.VUE_APP_I18N_DEFAULT_LOCALE;
    }
  },
  SET_ACCOUNT(state, account) {
    state.account = account;
  },
  SET_EIC(state, account) {
    state.eic = account;
  },
  SET_GUEST(state, guest) {
    state.guest = guest;
  },
  SET_HIDDEN(state, hidden) {
    state.hidden = hidden;
  },
};

export const getters = {
  nativeData(state) {
    return state.nativeData;
  },
};

export const actions = {
  async authenticate({ commit, dispatch }, authData) {
    commit("SET_NATIVE_DATA", authData);
    dispatch("newNativeData");
  },
  async storeGuest({ state }, callBackFn) {
    Account.guestMode(state.guest, (res) => {
      callBackFn(res);
    });
  },
  async getGuest({ commit }) {
    await Account.getGuestMode((response) => {
      commit("SET_GUEST", response.guest);
    });
  },
  async setupUserSettings({ dispatch, commit, state }) {
    await Settings.getLanguage(async (lang) => {
      let selectedLocale;
      if (lang.language === null) {
        Settings.putLanguage(state.nativeData.lang);
        selectedLocale = state.nativeData.lang;
      } else {
        selectedLocale = lang.language;
      }
      commit("SET_LOCALE", selectedLocale);
      commit("Settings/UPDATE_LANGUAGE", selectedLocale, { root: true });
    });
    await Auth.getPhoneNumber((response) => {
      commit("SET_PHONE_NUMBER", response.phone_for_save_card);
    });
    dispatch("getAndSetupTheme");
  },
  async setUserPhone({ state, commit }) {
    console.log("TRY TO SET PHONE");
    if (state.authToken !== null && state.authToken !== undefined) {
      await Auth.getPhoneNumber((response) => {
        console.log("SET PHONE: " + response.phone_for_save_card);
        commit("SET_PHONE_NUMBER", response.phone_for_save_card);
        window.Tawk_API.setAttributes({
          phone: response.phone_for_save_card,
          link: "https://support.billing.city/user/" + response.id,
        });
      });
    }
  },
  async getAndSetupTheme({ commit, state }, theme) {
    await Settings.getTheme((res) => {
      console.log("GET AND SETUP THEME");
      state.themeReady = false;
      const themeToSetup = res.theme ? res.theme : theme ? theme : "dark";
      commit("SET_APP_THEME", themeToSetup);
      appBridge.methods.nativeSendEvent("setThemeMode", themeToSetup);
    });
  },
  async refreshToken({ commit }) {
    return Account.refreshToken((response) => {
      commit("SET_AUTH_TOKEN", response.access_token);
    });
  },
  async importAccounts() {
    return Account.importAccounts((res) => {
      return res.mode;
    });
  },
  async getUserAccounts({ commit }) {
    return Account.getAccounts((response) => {
      return commit("SET_ACCOUNTS", response);
    });
  },
  getUserAccount({ commit }, account) {
    return Account.getAccount(account, (response) => {
      commit("SET_ACCOUNT", response);
      commit("SET_EIC", response);
      commit("Payment/UPDATE_AMOUNT", response.balance, { root: true });
      commit("IndicationsHistory/SET_TARIFF", response.tariff, { root: true });
    });
  },
  storeNewAcont({ state }, callBackFn) {
    Account.storeAccount(state.accountNumber, (res) => {
      callBackFn(res);
    });
  },
  storeNewAcontEic({ state }, callBackFn) {
    Account.storeAccountEic(state.accountNumber, (res) => {
      callBackFn(res);
    });
  },
  newNativeData({ state, commit }) {
    Account.nativeAuth(state.nativeData).then((response) => {
      commit("SET_AUTH_TOKEN", response.data.access_token);
    });
  },
  newGoogleAuth({ state }, callBackFn) {
    Account.googleAuth(state.nativeData, (res) => {
      callBackFn(res);
    }).then(() => {
      location.reload();
    });
  },
  deleteUserAccount({ dispatch }, accountNumber) {
    return Account.deleteAccountByNumber(accountNumber, () => {
      dispatch("getUserAccounts");
    });
  },
  deleteUser(callBackFn){
    return Account.deleteUser((res) => {
      callBackFn(res);
    })
        .then( () => {
          this.$forceUpdate();
        });
  },
  getUserLanguage({ commit }) {
    return Settings.getLanguage((response) => {
      return commit("SET_LOCALE", response);
    });
  },
};
