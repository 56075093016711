<template>
  <van-row
      justify="center"
      class="page-wrapper have-bottom-menu account-list-page"
  >
    <van-col span="24" class="itserve-center round-shadow-btn">
      <div class="place-content-center">
        <info-message class="apply-loader">
          {{ $t("deleteUser.deleteInfo") }}
        </info-message>
      </div>
      <itserve-button
          :value="$t('buttons.deleteUser')"
          type="primary"
          @click="deleteUser"
          class="mt-15 apply-loader"
      ></itserve-button>
    </van-col>
  </van-row>
</template>
<script>
import Dialog from "vant/lib/dialog/index";
import ItserveButton from "@/components/forms/ItserveButton";
import InfoMessage from "@/components/content/InfoMessage";
import "vue-awesome/icons/plus";
export default {
  name: "DeleteUser",
  components: {
    InfoMessage,
    ItserveButton,
  },

  computed: {

  },
  methods: {

    deleteUser() {
      Dialog.confirm({
        title: this.$t("deleteUser.dialogDeleteMessage"),
        message: this.$t("accountList.dialogQuestion"),
        cancelButtonText: this.$t("accountList.dialogCancel"),
        confirmButtonText: this.$t("accountList.dialogConfirm"),
      }).then(() => {
        this.$store.dispatch("Auth/deleteUser");
        this.$store.state.Auth.authToken = undefined;
      }).catch((error) => {
        console.log(error);
      });
    },
  },
};
</script>
<style>
.account-list-page .info-content {
  text-align: center !important;
}
</style>
<style scoped>
@import "../../../node_modules/vant/lib/cell/index.css";
@import "../../../node_modules/vant/lib/dialog/index.css";
@import "../../../node_modules/vant/lib/overlay/index.css";
@import "../../../node_modules/vant/lib/popup/index.css";
</style>
